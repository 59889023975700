import React from "react"
import { Row, Col } from "react-bootstrap"
import Img from "gatsby-image"
import { Link } from "gatsby"

const FeaturedPost = ({ post }) => {
  const { category, title, previewImage, publishedOn, slug } = post

  return (
    <article>
      <Row className="py-5 mt-5">
        <Col className="d-flex flex-column justify-content-between">
          <Link to={`/blog/${category[0].slug}/`}>
            <h4 className="secondary-blue-2-text text-uppercase">
              {category[0].name}
            </h4>
          </Link>
          <Link to={`/blog/${category[0].slug}/${slug}/`}>
            <h1 className="font-weight-bold">{title}</h1>
            <div>
              <p>
                Integration is key to digitization - and it’s not just Fintech
                companies that have embraced it. Financial institutions like
                banks are now adopting the integrated concept as well. Banks are
                becoming more accessible and welcoming in a new era of client
                relations: open banking.
              </p>
              <small className="secondary-blue-2-text">
                {publishedOn} &nbsp;&bull;&nbsp; 4 minutes
              </small>
            </div>
          </Link>
        </Col>
        <Col>
          <Link to={`/blog/${category[0].slug}/${slug}/`}>
            <Img fluid={previewImage && previewImage.fluid} style={{ borderRadius: "16px" }} />
          </Link>
        </Col>
      </Row>
    </article>
  )
}

export default FeaturedPost
