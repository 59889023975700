import styled from "styled-components"
import { Nav } from "react-bootstrap"
import { colors } from "../../../utils/styles";

export const CategoryWrapper = styled(Nav)`
  .customized-dropdown {
    button {
      background: white;
      color: green;
    }
    .btn {
    /* width: 100%; */
    }
    .dropdown-subtext{
      font-size: 0.8rem;
    }
  }
  .nav-item {
    .nav-link {
      background-color: unset;
      &.active {
        border-bottom: 4px solid ${colors.secondaryBlue2} !important;
        color: ${colors.secondaryBlue2};
        font-weight: bold;
      }
      &:hover {
        border-bottom: 4px solid ${colors.secondaryBlue2} !important;
        color: white;
      }
    }
    font-size: 0.9rem;
  }
`
