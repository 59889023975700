import React from "react"
import { Nav, Container, Dropdown } from "react-bootstrap"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import { CategoryWrapper } from "./BlogCategories.styles"
import { LINKS } from "../../../data/pageLinks.data"

const BlogCategories = ({ active }) => {
  const data = useStaticQuery(graphql`
    {
      allContentfulBlogPostCategory(filter: { name: { ne: "internal" } }) {
        edges {
          node {
            id
            name
            slug
          }
        }
      }
    }
  `)

  const categories = data.allContentfulBlogPostCategory.edges

  return (
    <CategoryWrapper
      variant="tabs"
      className="ap-tabs secondary-blue-3-background "
    >
      <Container className="d-block d-lg-none mb-5">
        <Dropdown className="">
          <Dropdown.Toggle
            variant="light"
            id="dropdown-basic"
            style={{ color: "#888989" }}
            className="text-capitalize mx-3"
          >
              <span className="">{active || "All Posts"}</span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item as={Link} to={LINKS.blog}>
              All Posts
            </Dropdown.Item>
            {categories.map(({ node: category }) => {
              return (
                <Dropdown.Item as={Link} to={`/blog/${category.slug}/`}>
                  {category.name}
                </Dropdown.Item>
              )
            })}
          </Dropdown.Menu>
        </Dropdown>
      </Container>
      <Container className="d-none d-lg-flex">
        <Nav.Item>
          <Nav.Link
            as={Link}
            to={LINKS.blog}
            className={`border-0 pl-0 pr-0 mr-4 poppins text-white ${
              active === undefined ? "active " : ""
            }`}
          >
            All Posts
          </Nav.Link>
        </Nav.Item>
        {categories.map(({ node: category }) => {
          return (
            <Nav.Item key={category.id}>
              <Nav.Link
                as={Link}
                className={`border-0 pl-0 pr-0 mr-4 poppins text-white ${
                  category.slug === active ? "active " : ""
                }`}
                to={`/blog/${category.slug}/`}
              >
                {category.name}
              </Nav.Link>
            </Nav.Item>
          )
        })}
      </Container>
    </CategoryWrapper>
  )
}

export default BlogCategories
