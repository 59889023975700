import styled from "styled-components"
import { Jumbotron } from "react-bootstrap"

export const HeroSectionWrapper = styled(Jumbotron)`
  h1 {
    color: #4cb9ef;
    font-weight: bold;
  }
  p {
    @media (min-width:992px) {
      font-size: 1.3rem;
    }
  }
  border-radius: 0.3em 0.3em 0px 0px ;
`
