import React from "react"
import { Link } from "gatsby"
import { PagerWrapper } from "./Pager.styles"

const Pager = ({ pageContext }) => {
  const { previousPagePath, nextPagePath } = pageContext
  return (
    <PagerWrapper className="d-flex justify-content-end">
      <Link
        to={previousPagePath}
        className={`mr-3 ${
          previousPagePath ? "" : "disabled"
        } primary-blue-text`}
      >
        Previous
      </Link>
      <Link
        to={nextPagePath}
        className={`${nextPagePath ? "" : "disabled"} primary-blue-text`}
      >
        Next
      </Link>
    </PagerWrapper>
  )
}

export default Pager
