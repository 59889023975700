import React from "react"
import { HeroSectionWrapper } from "./HeroSection.styles"
import { Container } from "react-bootstrap"

const HeroSection = () => {
  return (
    <HeroSectionWrapper className="mb-0 secondary-blue-3-background">
      <Container>
        <h1>Blog</h1>
        <p className="text-white pr-lg-5 mr-lg-5">
          Dive into the world of payment technology. Explore insights. And stay
          up to date with product and company news.
        </p>
      </Container>
    </HeroSectionWrapper>
  )
}

export default HeroSection
