import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import PostPreview from "./PostPreview"
import FeaturedPost from "./FeaturedPost"
import BlogCategories from "./BlogCategories"

import Pager from "./Pager"

const TabsSection = ({ posts, showFeatured, showCategory, pageContext }) => {
  const featuredPost = posts.find((post) => post.node.featured)
  const displayFeatured = showFeatured && featuredPost
  const {
    category: active,
    previousPagePath,
    nextPagePath,
    numberOfPages,
  } = pageContext

  return (
    <>
      <BlogCategories active={active} />
      <Container className="px-4">
        {displayFeatured && <FeaturedPost post={featuredPost.node} />}
        <Row className="mt-5">
          {posts.map((post) => (
            <Col key={post.node.id} md={3}>
              <PostPreview post={post} showCategory={showCategory} />
            </Col>
          ))}
        </Row>
        {numberOfPages > 1 ? <Pager pageContext={pageContext} /> : null}
      </Container>
    </>
  )
}

export default TabsSection
